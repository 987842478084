.App {
  text-align: left;
  background-color: #1b1b1b;

}

.pag{

  list-style-type: none;
  color: #ddd;
}
.act{
  background-color: #ff0000 !important;

}

.each{
  width: 50px;
  background-color: #1b1b1b;
  border-radius: 10px;
  height: 30px;
  align-items: center;
  text-align: center;
  display: inline-block !important;
  margin-inline: 10px;
}
.eachL{
  text-decoration: none;
  color: #ddd;
}
.atag{
  text-decoration: none;
  display: inline-block !important;
  margin-right: 10px;
}



.nextL{
  text-decoration: none;
}
.prevL{
  text-decoration: none;
}
.next{
  width: 60px;
  background-color: #1b1b1b;
  border-radius: 10px;
  height: 30px;
  align-items: center;
  text-align: center;
  display: inline-block !important;
}
.prev{
  width: 60px;
  background-color: #1b1b1b;
  border-radius: 10px;
  height: 30px;
  align-items: center;
  text-align: center;
  display: inline-block !important;
}









.sm-h {
  display: none;
}
.vidSize{
  width: 100% !important;
  height: 450px !important;
}
.txtbox {
  height: 42px !important;
  width : 300px !important;
}
.rgt-ads {
  display: block;
}
.acc-det {
  display: block;
}
.acc-dets {
  display: none !important;
}
.hide {
  display: none;
}
.big-show {
  display: none;
}
.logon {
  display: none;
}
.banner-wrap {
  border-radius: 7px;
  overflow: hidden;
  float: left;
  width: 100%;
  position: relative;
  background-color: rgb(241, 12, 12);
  height: 330px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 5px 10px rgba(27,27,27, 0.3);

}

.left-wrap h4 {
  font-size: 48px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  font-weight: 700;
  line-height: 1.1;
}



















@media only screen and (min-width: 1450px) {
  .show-bx {
    height: 120px !important;
  }
  .show-bxs {
    width: 100%v!important;
    height: 120px !important;
  }

  .hide {
    display: none !important;
  }
  .icn-hm {
    margin-right: 30px !important;
  }
  .show-tm {
    margin-top: -10px !important;

  }
  .show-pay {
    margin-top: -10px !important;
  }
  .show-ply {
    margin-top:-10px !important;
  }
}






@media only screen and (max-width: 1450px) {
  .show-bx {
    height: 120px !important;
  }
  .show-bxs {
    width: 100%v!important;
    height: 120px !important;
  }
  .hide {
    display: none !important;
  }
  .icn-hm {
    margin-right: 30px !important;
  }
  .show-ply {
    margin-top:-10px !important;
  }

}





























@media only screen and (min-width: 1200px) {
  .sm-h {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .left-wrap .tag {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1200px) {
  .left-wrap h2 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .left-wrap p {
    font-size: 13px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 1200px) {
  .banner-wrap {
    height: 300px;
  }
  .text-sml {
    display: none;

  }
  .left-wrap {
    flex: 0 0 64%;
    max-width: 60%;
    position: relative;
    padding: 0 0 0 40px;

  }
  .right-wrap {
    flex: 0 0 40%;
    max-width: 60%;
    position: relative;
    height: 100%;
    background-size: cover;

  }
  .img-sm {
    height: 25px !important;
    width: 10px !important;
  }
  .img-sml {
    height: 25px !important;
    width: 25px !important;
    margin-right: -13px !important;
  }
  .full-und {
    left: 0% !important;
    width: 40px !important;
  }
  .bar-sp {
    height:200px;
  }
  .hide-rgt {
    display: none !important;
  }
  .src-nav {
    display: none ;
  }
  .acc-nav {
    display: none ;
  }
  .hide {
    display: none !important;
  }
  .icn-hm {
    margin-right: 30px !important;
  }
  .rgt-ads {
    display: none !important;
  }
  .show-tm {
    margin-top: -10px !important;

  }
  .show-pay {
    margin-top: -10px !important;
  }
  .show-ply {
    margin-top:-10px !important;
    font-size: "400%" !important;
  }
}










@media only screen and (max-width: 992px) {
  .left-wrap {
    padding: 0 0 0 15px;
  }
  .banner-wrap {
    display: none;
  }
  .shift-ftr {
    width: 100% !important;
    margin-left: 0px !important;


  }
  .show-lk {
    margin-top: --10px !important;

  }
  .show-v {
    margin-top: --10px !important;

  }
  .show-tm {
    margin-top: --10px !important;

  }
  .show-pay {
    margin-top: -10px !important;

  }
  .hide-ftr {
    display: none !important;
  }
  .hide-rgt {
    display: none !important;
  }
  .shift-hm {
    margin-left: 0px !important;
  }
  .hide {
    display: block !important;
  }

}
@media only screen and (max-width: 992px) {
  .left-wrap p {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .left-wrap h2 {
    font-size: 36px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 992px) {
  .left-wrap a {
    margin-top: 10px;
  }
  .logos {
    position: absolute;
    display: none;
    margin-bottom: -50;
  }
  .navis {
    display: none;
  }
  .logon {
    display: block;
  }
  .src-nav {
    display: block !important;
  }
  .acc-nav {
    display: block !important;
  }
  .icn-hm {
    margin-right: 30px !important;
  }

  .rgt-ads {
    display: none !important;
  }
  .acc-det {
    display: none !important;
  }
  .acc-dets {
    display: block !important;
  }
  .show-ply {
    margin-top:-10px !important;
  }
  .closemenu {
    margin-left: 45px !important;
  }
}







.src-nav {
  display: none ;
}
.acc-nav {
  display: none ;
}























@media only screen and (max-width: 830px) {
  .show-bx {
    height: 120px !important;
  }
  .show-bxs {
    width: 100%v!important;
    height: 120px !important;
  }
  .show-lk {
    margin-top: -10px !important;

  }
  .show-v {
    margin-top: -10px !important;

  }
  .show-ply {
    margin-top:-10px !important;
    font-size: "300%" !important;
  }
  .show-tm {
    margin-top: -10px !important;

  }
  .show-pay {
    margin-top: -10px !important;

  }
  .src-nav {
    display: block !important;
  }
  .acc-nav {
    display: block !important;
  }
  .icn-hm {
    margin-right: 30px !important;
  }
  .rgt-ads {
    display: none !important;
  }
  .acc-det {
    display: none !important;
  }
  .acc-dets {
    display: block !important;
  }
  .closemenu {
    margin-left: 40px !important;
  }
  .cut-v {
    height: 380px !important;
  }
}































@media only screen and (max-width: 576px) {
  .banner-wrap {
    display: none;
  }

}
.left-wrap {
  flex: 0 0 55%;
  max-width: 50%;
  position: relative;
  padding: 0 0 0 50px;

}
@media only screen and (max-width: 576px) {
  .show-bx {
    height: 100px !important;
  }
  .spc {
    width: 220px !important;

  }
  .show-bxs {
    width: 100% !important;
    height: 100px !important;
    object-fit:fill;
  }
  .show-ply {
    margin-left: 45px !important;
  margin-top:12px !important;
  }
  .show-px {
    width: 20px !important;
    height: 20px !important;
    margin-top: -15px !important;
  }
  .show-tx {
    font-size: 8px !important;
    margin-left: -10px !important;

  }
  .show-lk {
    height: 15px !important;
    width: 80px !important;
    margin-top: -10px !important;

  }
  .show-v {
    height: 15px !important;
    width: 50px !important;
    margin-top: -10px !important;

  }

  .show-v  i {
    font-size: 10px !important;
    margin-left: -5px !important;
    margin-top: -1px !important;

  }
  .show-v  p {
    font-size: 6px !important;
    margin-left: 5px !important;
    margin-top: 2px !important;
    text-align: left;
  }

  .show-lk  i {
    font-size: 10px !important;
    margin-left: -5px !important;
    margin-top: -1px !important;

  }
  .show-lk  p {
    font-size: 6px !important;
    margin-left: 5px !important;
    margin-top: 2px !important;
    text-align: left;
  }


  .show-tm {
    margin-top: 0px !important;
    height: 15px !important;
    width: 40px !important;

  }
  .show-tm  p {
    font-size: 7px !important;
    padding-left: 5px;
    padding-right: 0px;
    text-align: center;

  }

  .show-pay {
    margin-top: -1px !important;
    padding-left: 5px;
    padding-right: 0px;
    height: 15px !important;
    width: 69px !important;
  }
  .show-pay i {
    margin-top: 3px !important;
    font-size: 7px !important;
  }
  .show-pay p {
    margin-top: 3px !important;
    font-size: 7px !important;
    padding-left: 0px;
    padding-right: 0px;
    width: 50% !important;
    margin-left: 0px !important;
  }

  .left-wrap {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 20px;
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    background: linear-gradient(to top, #211813, transparent);
  }
  .logos {
    position: absolute;
    display: none;
    margin-bottom: -50;
  }
  .navis {
    display: none;
  }
  .logon {
    display: block;
  }
}

@media only screen and (max-width: 576px) {
  .right-wrap {
    flex: 0 0 100%;
    max-width: 100%;
    height: 80%;
  }
}

@media only screen and (max-width: 576px) {
  .right-wrap video {
    height: 250px;
  }
}

@media only screen and (max-width: 576px) {
  .left-wrap h2 {
    font-size: 26px;
    margin-top: 10px;
  }
  .main-p {
    margin-left: -5px !important;
  }
  .main-icn {
    margin-left: 41.5% !important;
  }
  .src-nav {
    display: block ;
  }
  .acc-nav {
    display: block ;
  }
  .icn-hm {
    margin-right: 30px !important;
  }
  .big-show {
    display: block;
  }
  .rgt-ads {
    display: none !important;
  }
  .acc-det {
    display: none !important;
  }
  .acc-dets {
    display: block !important;
  }
  .shift-lft {
    margin-left: -15px !important;
  }
  .closemenu {
    margin-left: 35px !important;
  }
  .cut-vs {
    height: 320px !important;
  }
  .cut-v {
    height: 480px !important;
  }
  .div-v {
    width: 180px !important;
    margin-right: 5px  !important;
  }
  .div-vs {
    height: 40px !important;
  }
  .cut-vp {
    height: 590px !important;
  }
  .div-v p {
    font-size: 8px !important;
  }

}























@media only screen and (max-width: 400px) {
  .show-bx {
    height: 90px !important;
  }
  .show-bxs {
    width: 100% !important;
    height: 90px !important;
  }

  .src-nav {
    display: block ;
  }
  .acc-nav {
    display: block ;
  }
  .show-lk {
    margin-top: -10px !important;

  }
  .show-ply {
    margin-top: 8px  !important;
    }
  .show-tm {
    margin-top: -15px !important;

  }
  .show-pay {
    margin-top: -15px !important;

  }
  .src-nav i {
    margin-left: -15px !important;
    width: 5% !important;
  }
  .acc-nav {
    margin-left: 10px !important;
  }
  .icn-hm {
    margin-right: 25px !important;
  }
  .shift-lft {
    margin-left: -15px !important;
  }
  .rgt-ads {
    display: none !important;
  }
  .acc-det {
    display: none !important;
  }
  .closemenu {
    margin-left: 15px !important;
  }
  .cut-v {
    height: 450px !important;
  }
  .div-v {
    max-width: 170px !important;
    margin-right: 5px !important;
  }
  .div-v p {
    font-size: 8px !important;
  }


}


@media only screen and (max-width: 320px) {
  .show-bx {
    height: 80px !important;
  }

  .show-ply {
    margin-left: 20px !important;
  margin-top:-7px !important;
  }
  .show-tm {
    margin-top: -5px !important;

  }
  .show-pay {
    margin-top: -15px !important;

  }
  .src-nav {
    display: block ;
  }
  .acc-nav {
    display: block ;
  }
  .tags {
    width: 30% !important;
  }
  .icn-hm {
    margin-right: 20px !important;
  }
  .big-show {
    display: block;
  }
  .rgt-ads {
    display: none !important;
  }
  .acc-det {
    display: none !important;
  }
  .acc-dets {
    display: block !important;
  }
  .cut-v {
    height: 350px !important;
  }
  .div-v {
    width: 180px !important;
    margin-right: 5px  !important;
  }
}








.left-wrap .tag {
  font-size: 14px;
  color: #ddd;
  margin-right: 15px;
}

.left-wrap .tag b {
  color: #fff;
}
.left-wrap .rnd {
  border: 1px #ddd solid;
  line-height: 20px;
  padding: 0 10px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  color: #fff;
}
.left-wrap p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 28px;
}
.right-wrap {
  flex: 0 0 55%;
  max-width: 40%;
  position: relative;
  height: 100%;
  background-size: cover;


}
.right-wrap:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 60%;
  height: 100%;
  background: linear-gradient(to right, #ff0000, transparent);
}
.right-wrap video {
  margin-top: -12px;
  margin-left: 35px;
  height: 500px;
}


.App-header {
  background-color: #1b1b1b;

  text-align: left;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-row {
  height: 97vh;
  width: 100%;
}


.sideD :hover{
  background-color: #121212;
}
.tag-containers {
  min-width: 60%;
  max-width: 75%;
  display: flex;
  flex-wrap: wrap;
  min-height: 30px;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
}
.tagss {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  height: 25px;
  margin: 2px 5px 2px 0px;
  color: white;
}

.tag-containers input {
  border: none;
  flex: 1;
  outline: none;
  padding: 5;
  background: black;
  color: white;
}
.tagss span {
  margin-left: 5px;
  cursor: pointer;
}

.horImageCon {
  display: flex;
  width: 100%;
  overflow: auto;
}